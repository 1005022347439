.light-nav-tabs{
  .app-label{
    top:-5px !important;
  }
}

// app 状态
.app-status{
  .app-text{
    .status{
      &.enabled{
        color: $successColor;
      }
      &.disabled{
        color: $warnColor;
      }
    }
  }
}

.swal2-container {
  z-index:1988;
}

// 付费请求
.premium-required{
  cursor: not-allowed;
  position: relative;
  &:before{
    position: absolute;
    display: block;
    content: " ";
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:10;
    //background-color: rgba(255,255,255,.5);
  }
  .premium-text{
    color: $premiumBadgeColor;
    display: inline-block;
  }
  .cover-badge{
    position: absolute;
    display: block;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:12;
    color: $premiumBadgeColor;
    font-size:16px;
    .i-badge{
      font-size:18px;
      vertical-align:middle;
    }
  }
}
.premium-text{
  display: none;
}
.cover-badge{
  display: none;
}

.i-badge{
  color: $premiumBadgeColor;
  font-size:16px;
  vertical-align: middle;
}

// plan弹窗
.pricing-dialog-popup{
  @media (min-width: 768px) {
    .ajs-dialog{
      max-width: 750px;
    }
  }
  .ajs-header{
    background:#fff !important;
    border-bottom:none;
    text-align: center;
    color: $mainFontColor;
    font:{
      size:20px;
    }
    line-height:1.2em;
  }
  .ajs-footer{
    //display: none;
    background:#fff !important;
    border-top:none;
  }
  .pricing-dialog{
    .pthread{
      font:{
        size:25px;
      }
      line-height:1.2em;
    }
    .pricing-plans{
      text-align: center;
      padding: 30px 0;
      position: relative;
      .badge-box{
        margin:10px auto;
        .icon{
          font-size:48px;
        }
      }
      .pricing-plan{
        display: inline-block;
        padding:20px;
        border: 1px solid #e4e5f0;
        .title{
          font-weight:600;
        }
        .money{
          .price{
            font-size:40px;
            line-height: 42px;
          }
        }
        .new-price{
          display: none;
        }
      }
    }
    .btns{
      text-align: center;
    }
    
    .plan-tables{
      text-align: center;
      .plan-table{
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width:45%;
        overflow: hidden;
        -webkit-box-shadow: 0 2px 5px rgba(0,0,0,.2);
        box-shadow: 0 2px 5px rgba(0,0,0,.2);
        padding: 20px;
        position: relative;
        min-height: 480px;
        &:last-child{
          margin-left:20px;
        }
        .plan-label{
          position: absolute;
          top: 15px;
          right: -25px;
          padding: 4px 0;
          width: 100px;
          background-color: #e1e1e1;
          -webkit-transform: rotate(46deg);
          transform: rotate(46deg);
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
        }
        &.free-plan{
          .plan-label{
            background-color: #ff6524;
          }
        }
        &.recommend-plan{
          .plan-label{
            background-color: #31b73f;
          }
          .sale-price{
            color: #6f6cea;
          }
        }
        .plan-features{
          .plan-feature{
            line-height: 2em;
            vertical-align: middle;
            font-size:14px;
            &:before{
              content: "";
              display: inline-block;
              position: relative;
              top: -2px;
              width: 15px;
              height: 15px;
              margin-right: 10px;
              vertical-align: middle;
              background-image: url("../../base/img/pricing-feature-active.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
        .plan-name{
          text-align: center;
        }
        .sale-price{
          text-align: center;
          margin: 5px 0 15px;
          font-size: 32px;
          font-weight: 700;
          color: #5b5b5b;
          .pricing-currency{
            display: inline-block;
          }
        }
        .original-price{
          display:inline-block;
          color: #333;
          font-size:15px;
          text-decoration: line-through;
        }
        .upgrade-wrapper{
          position: absolute;
          bottom:30px;
          left:50%;
          transform: translateX(-50%);
          .free-trial-tips{
            color: #4b696c;
          }
        }
        .upgrade-btn{
          margin-top:0px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}

.leave-review-tips{
  .reviewed-btn{
    color: $primaryColor;
  }
}

// App底部推荐其他app
.recommend-apps{
  .app-item{
    padding: 5px;
    transition: all .2s;
    overflow: visible;
    &:hover{
      .media-object{
        transform: scale(1.002);
      }
      background-color: #e0f3ff;
    }
    .media-object{
      height:100px;
      width:100px;
      transition: all .2s;
    }
    .app-description{
      overflow: hidden;
      //white-space: nowrap;
      text-overflow: ellipsis;
      max-height: 50px;
      font-size:12px;
      line-height: 1.5em;
    }
    .media-heading{
      font-size:14px;
      font-weight: 500;
      position: relative;
      .app-label{
        position: absolute;
        right:0;
        top:-10px;
        &.app-label-new{
          .iconfont{
            color: #E32C2B;
          }
        }
      }
    }
    .media-body{
      overflow: visible;
      > a{
        color: $primaryColor;
      }
    }
  }
}

.label-wrapper{
  position: relative;
  .app-label{
    position: absolute;
    right:0;
    top:-20px;
    &.app-label-new{
      .iconfont{
        color: #E32C2B;
      }
    }
  }
}

.pretty input:checked ~ .state.p-primary-o label:before, .pretty.p-toggle .state.p-primary-o label:before{
  border-color: $primaryColor;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after{
  background-color: $primaryColor !important;
}
.pretty.p-switch.p-qimify{
  .state:before{
    //border-color: $primaryColor;
  }
  input:checked ~ .state:before{
    border-color: $primaryColor;
  }
  input:checked ~ .state label:after{
    background-color: $primaryColor !important;
  }
}

.selectize-control{
  &.loading{
    &::before{
      opacity: .4;
    }
  }
  &::before{
    transition: opacity 0.2s;
    content: ' ';
    z-index: 2;
    position: absolute;
    display: block;
    top: 50%;
    right: 34px;
    width: 16px;
    height: 16px;
    margin: -10px 0 0 0;
    background: url(../img/spinner.gif);
    background-size: 16px 16px;
    opacity: 0;
  }
  &.multi{
    &::before{
      right: 10px;
    }
  }
  .selectize-input {
    border-color: #d2d6de;
    border-radius: 0;
  }
}

.badge-groups{
  .qimify-trust-badge{
    background-color: #e5e5e5;
    cursor: pointer;
    margin-right: 2px;
    &:hover, &.selected{
      background-color: #6b15b6;
      color: #fff;
    }
  }
  .qimify-trust-badge-paypal{
    padding: 0 2px;
  }
}

.empty-data{
  text-align: left;
  padding: 40px;
  color: $mainFontColor !important;
  position: relative;
  .empty-main{
    float:left;
    position: absolute;
    top:50%;
    transform: translateY(-100%);
  }
  .empty-img{
    width: 50%;
    float: right;
  }
  .empty-tip-headline{
    font-size: 20px;
    letter-spacing: .3px;
    font-weight: 600;
    color: rgb(60, 72, 88);
    line-height: 1.3em;
  }

  .empty-tip-message{
    margin: 20px 0;
  }

  .empty-options{
    .btn{
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.box-info{
  .subheading{
    color: $metaColor;
    font-size:13px;
  }
  .tips{
    color: $metaColor;
    font-size:13px;
  }
}

// 应用加速提示框
.fast-mode-box{
    h3{
      font:{
        size: 14px;
        weight: 500;
      }
      line-height: 1.5em;
      margin: 0;
    }
    pre{
      padding: 8px;
      background-color: #f8f9fa;
      box-shadow: inset 0 1px 0 0 rgba(99,115,129,0.05);
      border: 1px solid #c4cdd5;
      border-radius: 3px;
    }
}

.pagination-info{
  .page-item{
    a{
      color: #212b36;
      border-color: #c4cdd5;
    }
    &.disabled{
      a{
        background: #f4f6f8;
        border-color: #c4cdd5;
        color: #919eab;
      }
    }
  }
}
// info
$defaultColor: #dfe3e8;
$attentionColor: #ffea8a;
$infoColor: #b4e1fa;
$successColor: #bbe5b3;
$warnColor: #ffc58b;
$pinkColor: #e70659;
//链接色
$linkColor: #6371c7;
$primaryColor: #6371c7;
//$linkColor: #778087;
//meta颜色
//$metaColor: #bab9bb;
$metaColor: #8590a6;

//主题色
$mainColor: #fff;

//背景色
$bodyBackgroundColor: #f9f9f9;

//边框色
$borderColor: #eee;
//主体字体颜色
$mainFontColor: #525252;
//着重字体色
$focusFontColor: #333;
$focusFontColor: #212b36;
//灰色字体
$grayFontColor: #818181;

//对比颜色
$contrastColor: rgb(108, 188, 119);

// 要求升级badge
$premiumBadgeColor: #f16d15;

//主要字体
$mainFontFamily: Source Sans Pro,Helvetica Neue,Helvetica,Arial,sans-serif;
$primaryColor: #694ccc;

a{
  color: rgb(99, 113, 199);
  &:hover, &:focus{
    color: #201CCC;
  }
}

.fixed{
  .left-menu-wrapper{
    position: fixed !important;
  }
}
.skin-deep-blue{

  .main-header .navbar .nav > li > a{
    color: $mainFontColor;
  }
  .skin-soft-blue .main-header .navbar .nav > li > a {
    color: #ffffff;
  }
   .main-header .navbar .nav > li > a:hover,
   .main-header .navbar .nav > li > a:active,
   .main-header .navbar .nav > li > a:focus,
   .main-header .navbar .nav .open > a,
   .main-header .navbar .nav .open > a:hover,
   .main-header .navbar .nav .open > a:focus,
   .main-header .navbar .nav > .active > a {
    background: #fff;
    color: $mainFontColor;
  }

  .main-header .navbar .sidebar-toggle{
    background-color: #fff;
    color: #222;
    &:hover{
      color: #333;
      background-color: #f6f6f6;
    }
  }
  .navbar-custom-menu{
    .fa,.dish{
      font-size:14px;
    }
    .dish{
      font-size:16px;
    }
  }

  .dropdown-menu > li > a > .dish{
    margin-right: 10px;
  }

  .sidebar-menu{
    li:hover, li:active,li.active{
      a{
        background-color: $primaryColor;
      }
    }
    li.active{
      a{
        border-left-color: $primaryColor;
      }
    }
    li{
      a{
        color: #fff;
      }
    }
  }

  // 菜单栏样式覆盖
  .sidebar-menu .treeview-menu > li > a{
    color: #fff;
  }
  .sidebar-menu > li.menu-open > a{
    background-color: #5037a5;
  }
  .sidebar-menu > li > .treeview-menu{
    background-color: #7258ca;
  }
  .treeview-menu > li > a > .iconfont, .treeview-menu > li > a > .fa {
    font-size:16px;
    width:26px;
  }

  .wrapper{
    background-color: #fff;
  }
  .main-header{
    .logo{
      width:185px;
      background-color: $primaryColor;
      &:hover{
        background-color: $primaryColor;
      }
    }
    .navbar{
      background-color: #fff;
      //width:calc(100vw - 185px);
      margin-left: 185px;
    }
  }
  .main-header{
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.06);
  }
  .main-sidebar{
    width:185px;
    overflow-x: hidden;
    background-color: $primaryColor;
    //background: linear-gradient(to bottom, #694ccc,#694ccc);
  }
  .content{
    &.no-padding{
      padding:0;
    }
  }
  .content-wrapper, .main-footer {
    margin-left: 185px;
  }
  .content-wrapper{
    .left-menu-wrapper{
      position: absolute;
      width:200px;
      background-color: #fff;
      a{
        color: $focusFontColor !important;
      }
      .menu-header{
        text-align: center;
        padding: 25px 0;
        border-bottom: 1px solid #f4f4f4;
        font-size:18px;
        line-height: 1em;
      }
      .sidebar-menu li:hover a,
      .sidebar-menu li:active a,
      .sidebar-menu li.active a{
        background-color: #fff;
        color: $focusFontColor;
      }
      .sidebar-menu > li.menu-open > a{
        background-color: #fff;
        color: $focusFontColor;
      }
      .sidebar-menu > li > .treeview-menu{
        background-color: #fff;
      }
    }
    .right-content{
      margin-left: 200px;
      padding:15px;
    }
  }

  .card, .box-primary{
    background: #fff;
    -webkit-box-shadow: 0 0 6px 2px rgba(0,0,0,.05);
    box-shadow: 0 0 6px 2px rgba(0,0,0,.05);
    border-radius: 6px;
    &.no-radius{
      border-radius: 0 !important;
    }
  }

  .card{
    padding:15px;
    margin-bottom:20px;
    .card-section{
      padding: 15px;
      border-top: 1px solid #dfe3e8;
    }
    &.with-section{ //分章节
      padding:0;
    }
  }
  .card-subheading{
    font-weight: 600;
    line-height: 1.6em;
    margin:0;
    margin-bottom:10px;
    font-size:14px;
  }
  .card-subdued{
    color: #637381;
  }

  .label-debug{
    background-color: #00acd6;
  }
}


// 默认按钮
.btn{
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.u-btn-primary{
  background-color: $btnPrimaryColor;
  border-color: $btnPrimaryColor;
  color: #fff;
  &:hover, &:active, &:focus{
    background-color: transparent;
    background-image: none;
    border-color: $btnPrimaryColor;
    color: $btnPrimaryColor;
  }
}

.u-btn-primary-o{
  background-color: transparent;
  background-image: none;
  border-color: $btnPrimaryColor;
  color: $btnPrimaryColor;
  &:hover, &:active{
    background-color: $btnPrimaryColor;
    border-color: $btnPrimaryColor;
    color: #fff;
  }
}

.ball-pulse{
  > div{
    background-color: $primaryColor;
  }
}
.action-loader{
  visibility: hidden;
  .ball-pulse{
    display: inline-block;
    vertical-align: middle;
  }
  &.visible{
    visibility: visible;
  }
}

.treeview-menu{
  display: none;
}

.checkbox label, .radio label{
  padding-left:0;
}

.selectize-dropdown-content{
  padding:5px !important;
}

.filter-zone{
  margin-bottom:10px;
}
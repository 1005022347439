.alert{
  transition: box-shadow .2s cubic-bezier(.64,0,.35,1);
  transition-delay: .1s;
  border:none !important;
  border-radius: 0 !important;

  &.alert-warning{
    background-color: #fcf8e3 !important;
    border-color: #faebcc !important;
    color: #8a6d3b !important;
  }
  &.alert-info{
    box-shadow: inset 0 3px 0 0 #47c1bf, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    background-color: #eef9f9 !important;
    color: $mainFontColor !important;
    .alert-wrapper{
      .alert-icon{
        background-color: #b7ecec;
        .fa,.iconfont{
          color: #00848e;
        }
      }
    }
  }
  &.alert-primary{
    transition: box-shadow .2s cubic-bezier(.64,0,.35,1);
    transition-delay: .1s;
    box-shadow: inset 0 3px 0 0 $primaryColor, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    background-color: #f6f9fc !important;
    color: $mainFontColor !important;
    border:none !important;
    border-radius: 0 !important;
    .alert-wrapper{
      .alert-icon{
        background-color: #dde7f3;
        background-color: #eef4fa;
        .fa,.iconfont{
          color: $primaryColor;
        }
      }
    }
  }

  .alert-wrapper{
    padding:5px;
    .alert-icon{
      display: inline-block;
      vertical-align: top;
      border-radius: 15px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      .fa, .iconfont{
        font-size:20px;
        color: #fff;
      }
    }
    .alert-content{
      display: inline-block;
      padding-left:10px;
      font-size:14px;
      ul{
        li{
          line-height: 1.5em;
        }
      }
    }
  }
  a.btn{
    text-decoration: none;
  }
  
  &.alert-tip{
    padding:5px;
    box-shadow: none;
    .alert-icon{
      vertical-align: middle;
    }
  }
}


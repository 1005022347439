
.ticket-form{
  .attachment-group{
    position: relative;
  }
  .choose-file{
    position: absolute;
    bottom:0;
  }
}

#ticket_attachment{
  opacity: 0;
  position: absolute;
  z-index:0;
  left:0;
}
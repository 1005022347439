// 样式调整
html,body{
  font: {
    size: 14px;
    family: $mainFontFamily
  }
  color: $focusFontColor;
  margin: 0;
}

a{
  color: $linkColor;
}

//.treeview-menu{
//  display: block;
//}
.navbar-custom-menu > .navbar-nav > li.user-menu > .dropdown-menu{
  width: 120%;
  text-align: center;
}
.main-sidebar{
  width:200px;
}
.content-wrapper, .main-footer{
  margin-left: 200px;
}
.content-wrapper{
  background-color: #f4f6f8;
}

.box-primary{
  box-shadow: none;
  border-top:0;
  background: inherit;
  .box-header{
    border-bottom: 1px solid #eee;
    border-bottom: none;
  }
}

.box-info{
  border-top: none;
}

.label-warning{
  color: $warnColor;
}

//.sidebar-menu >li>a>.fa, .sidebar-menu>li>a>.iconfont{
//  display: inline-block;
//  //width: 30px;
//}

.sidebar-menu2{
  a{
    font-weight: 500 !important;
  }
  .i-dashboard{
    color: #4b98fc;
  }
  .i-qudao{
    color: #ffc466;
  }
  .i-wangzhan,.i-bundle{
    color: #d78eff;
  }
  .i-weixingongzhonghao{
    color: #5fb878;
  }
  .i-xiaochengxu{
    color: rebeccapurple;
  }
  .i-weibo,.i-add{
    color: #f99;
  }
  .i-page{
    color: #f99;
  }
  .i-pid{
    color: #f16a83;
  }
  .i-api,.i-setting{
    color: #5fb878;
  }
  .i-contact-us{
    color: $metaColor;
  }
}

.treeview-menu > li{
  padding-left:17px;
}
// 数据为空时候的样式
.empty-data{
  text-align: center;
  color: $metaColor !important;
  .fa, .cube{
    margin-right: 5px;
    font-size:46px;
  }
}

// 按钮
.btn-primary{
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #6371c7;
  box-shadow: inset 0 1px 0 0 #6371c7, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
  color: #fff;
  fill: #fff;
  &:hover, &:focus{
    border-color: #6371c7 !important;
  }
  &:active, &.active{
    background: -webkit-gradient(linear, left top, left bottom, from(#3f4eae), to(#3f4eae));
    background: linear-gradient(to bottom, #3f4eae, #3f4eae);
    border-color: #38469b;
    -webkit-box-shadow: inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b;
    box-shadow: inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b;
  }
}
.btn-default{
  background: linear-gradient(180deg,#fff,#f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22,29,37,.05);

  &:active,&:focus{
    fill: #637381;
    color: #212b36;
    background: -webkit-linear-gradient(top, #f4f6f8, #f4f6f8);
    background: linear-gradient(180deg, #f4f6f8, #f4f6f8);
    border-color: #c4cdd5;
    box-shadow: 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
  }
}


.margin-top-10{
  margin-top: 10px !important;
}

.margin-top-15{
  margin-top: 10px !important;
}
// 修正宽度
.navbar-custom-menu > .navbar-nav > li.user-menu > .dropdown-menu{
  width: 110% !important;
}

.navbar-custom-menu{
  .dropdown-menu{
    li{
      a{
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        &:hover{
          background: #f4f4f4;
          text-decoration: none;
        }
      }
    }
  }
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%
}

input[type=range]:focus {
  outline: none
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #e8e9ea;
  border-radius: 1px;
  border: 0px solid #000000
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $primaryColor;
  height: 16px;
  width: 16px;
  border-radius: 25px;
  background: $primaryColor;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e8e9ea
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #e8e9ea;
  border-radius: 1px;
  border: 0px solid #000000
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $primaryColor;
  height: 16px;
  width: 16px;
  border-radius: 25px;
  background: $primaryColor;
  cursor: pointer
}

input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent
}

input[type=range]::-ms-fill-lower {
  background: #e8e9ea;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000
}

input[type=range]::-ms-fill-upper {
  background: #e8e9ea;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid $primaryColor;
  height: 16px;
  width: 16px;
  border-radius: 25px;
  background: $primaryColor;
  cursor: pointer
}

input[type=range]:focus::-ms-fill-lower {
  background: #e8e9ea
}

input[type=range]:focus::-ms-fill-upper {
  background: #e8e9ea
}

.color-success{
  color: $successColor
}
.color-warn{
  color: $warnColor
}
.color-info{
  color: $infoColor
}
.color-meta{
  color: $metaColor
}

.color-primary{
  color: $primaryColor;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
  background-color: $primaryColor;
  border-color: $primaryColor;
}

// range group
.range-group{
  input[type=range]{
    width: 95%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 16px !important;
  }
  .input-group-btn{
    .form-control{
      width: 55px;
      text-align: center;
      background: #ffffff;
      border-radius: 3px;
    }
  }
  input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
  }

  input[type="number"] {
    -moz-appearance: textfield
  }
}

// light风格的nav tabs
.light-nav-tabs{
  border-bottom: none;
  >li.active>a, >li.active>a:focus, >li.active>a:hover{
    border:none;
    border-bottom:2px solid $primaryColor;
  }
  > li > a:hover, > li > a:active, > li > a:focus{
    background: none;
    border-color:transparent;
  }
  > li > a{
    color: $focusFontColor;
  }
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover{
  background-color: $primaryColor;
}

// loading
.action-loader{
  text-align: center;
  .ball-pulse > div {
    width: 10px;
    height: 10px;
    background-color: $primaryColor;
  }
  visibility: hidden;
}


.tablist{
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #dfe3e8;
  > li {
    display: inline-block;
    > a{
      position: relative;
      display: block;
      padding: 10px 15px;
      margin-right: 2px;
      line-height: 1.42857143;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
    }
  }
  >li.active>a, >li.active>a:focus, >li.active>a:hover{
    border:none;
    border-bottom:2px solid $primaryColor;
  }
  > li > a:hover, > li > a:active, > li > a:focus{
    background: none;
    border-color:transparent;
  }
  > li > a{
    color: $focusFontColor;
  }
}

.tabcontent{
  padding:15px 0;
}

/*------------------------------------
  占位位置样式
------------------------------------*/

@keyframes shortLoading {
  from {
    left: 0;
  }
  to {
    left: 200px;
  }
}

@keyframes shortLoading {
  0% {
    width: 20%
  }
  50% {
    width: 40%
  }
  to {
    width: 20%
  }
}

@-webkit-keyframes shortLoading {
  0% {
    width: 20%
  }
  50% {
    width: 40%
  }
  to {
    width: 20%
  }
}

@keyframes loading {
  0% {
    width: 60%
  }
  50% {
    width: 100%
  }
  to {
    width: 60%
  }
}

@-webkit-keyframes loading {
  0% {
    width: 60%
  }
  50% {
    width: 100%
  }
  to {
    width: 60%
  }
}
.resource-placeholder {
  padding:10px;
  .bar {
    height: 16px;
    background-color: #eaeaea;
    border: 1px solid #eaeaea;
    animation: shortLoading 1s ease-in-out infinite;
    -webkit-animation: shortLoading 1s ease-in-out infinite;
    -moz-animation: shortLoading 1s ease-in-out infinite;
    -o-animation: shortLoading 1s ease-in-out infinite;
    margin: 10px 0;
  }
  .bar1{
    -webkit-animation-name: loading;
    -moz-animation-name: loading;
    -o-animation-name: loading;
    animation-name: loading;
    -webkit-animation-delay: -.5s;
    -moz-animation-delay: -.5s;
    -o-animation-delay: -.5s;
    animation-delay: -.5s;
  }
  .bar2{
    -webkit-animation-name: loading;
    -moz-animation-name: loading;
    -o-animation-name: loading;
    animation-name: loading;
  }
  .bar3{
  }
}

/* filter zone*/
.filter-zone{
  padding: 10px 5px 15px;
}

#nprogress {
  .bar {
    height: 3px;
    top: 50px;
    background-color: #47c1bf;
    .peg{
      display: none;
    }
  }
  .spinner-icon{
    border-top-color: #47c1bf;
    border-left-color: #47c1bf;
  }
}

.content-header{
  display: none;
}

.checkbox label, .radio label{
  padding-left: 0;
}

//simplemde
.editor-toolbar.fullscreen{
  z-index:9999;
}
.CodeMirror-fullscreen{
  z-index:9999;
}

.data-table{
  .action{
    a{
      color: $focusFontColor;
    }
  }
}

.form-group{
  .error{
    color: #cd0c1c;
    margin-top:5px;
  }
}

// 嵌入式app
.embed_app {
  .main-header{
    display: none;
  }
  .content-wrapper{
    padding-top:0;
  }
  .content-wrapper, .main-footer{
    margin-left:0;
  }
}
